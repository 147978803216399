const Boxes = {
  box1: function () {
		var brd2 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-11, 11, 11, -11],keepaspectratio: true, axis:true, ticks:false, grid:true, pan:{enabled:false}, zoom:{enabled:false}, showCopyright:false, showNavigation:false});

		brd2.create('text', [-8, 9, '<b>Straight Line &vert; y=mx+c</b>'], {fixed:true, fontSize:function(){return 32*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
		brd2.create('text', [-8,8, 'Equation of Line Joining Point A to Point B'], {fixed:true, fontSize:function(){return 22*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
		//brd2.create('text', [-3,12, 'Protractor to measure angle &ang;CAB of the triangle.'], {fixed:true, fontSize:function(){return 18*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
		//Tape measure
		brd2.create('point', [0,0], {name:'O',fixed:true,fillColor:'black', strokeColor:'black', face:'square', size:function(){return 3*brd2.canvasHeight/800},label:{fontSize:function(){return 18*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
		var Ptm1 = brd2.create('point', [-4, -4], {name:'A (drag me!)', fillColor:'orange', strokeColor:'black', face:'circle', size:function(){return 4*brd2.canvasHeight/800}, label:{fontSize:function(){return 18*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
		var Ptm2 = brd2.create('point', [3, 9], {name:'B (drag me!)', fillColor:'orange', strokeColor:'black', face:'square', face:'circle', size:function(){return 4*brd2.canvasHeight/800},label:{fontSize:function(){return 18*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
		//
		brd2.create('text', [-8, 7,  function(){return 'y = '+ ((Ptm2.Y()-Ptm1.Y())/(Ptm2.X()-Ptm1.X())).toFixed(2)+ ' x + '+ (-(Ptm2.Y()-Ptm1.Y())*Ptm1.X()/(Ptm2.X()-Ptm1.X())+Ptm1.Y()).toFixed(2)}], {fixed:true, fontSize:function(){return 22*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
		brd2.create('image', ['assets/random.svg', [-7.0, 6.3],[0.5, 0.5]], {opacity:1, rotate:-50});
		brd2.create('image', ['assets/random.svg', [-5.5, 6.3],[0.5, 0.5]], {opacity:1, rotate:-50});
		brd2.create('text', [-6.8, 5.6, 'm' ], {fixed:true, fontSize:function(){return 22*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
		brd2.create('text', [-5.3, 5.6, 'c' ], {fixed:true, fontSize:function(){return 22*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});

		//
		var Perp =brd2.create('point',[function(){return Ptm2.X();}, function(){return Ptm1.Y();}],{name:'C', fillColor:'orange', strokeColor:'black', face:'circle', size:function(){return 4*brd2.canvasHeight/800},label:{fontSize:function(){return 18*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
		//
		brd2.create('segment',[Ptm1, Perp], {strokeColor:'blue', strokeWidth:2, dash:1});
		brd2.create('segment',[Ptm2, Perp], {strokeColor:'blue', strokeWidth:2, dash:1});
		var Line1 = brd2.create('line', [Ptm1, Ptm2],{strokeWidth:2, strokeColor:'black', fixed:true});
		var Line2 = brd2.create('line', [[0,0],[0,1]],{visible:false, fixed:true});
		//var Line3 = brd2.create('line', [[0,0],[1,0]],{visible:false, fixed:true});
		//brd2.create('perpendicular', [Ptm1, Line2]);
		//brd2.create('perpendicular', [Ptm2, Line3]);
		//var Perp =brd2.create('point',[function(){return Ptm2.X();}, function(){return Ptm1.Y();}]);
		var IntY =brd2.create('intersection', [Line1, Line2],{name:'D',fillColor:'black', strokeColor:'black', face:'square', size:function(){return 3*brd2.canvasHeight/800},label:{fontSize:function(){return 18*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}})
		//var IntX =brd2.create('intersection', [Line1, Line3],{name:'X',fillColor:'black', strokeColor:'black', face:'square', size:function(){return 3*brd2.canvasHeight/800},label:{fontSize:function(){return 18*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}})
		//brd2.create('segment',[[0,0], IntX], {strokeColor:'blue', strokeWidth:2});
		brd2.create('segment',[[0,0], IntY], {strokeColor:'red', strokeWidth:2});
		brd2.create('image', ['assets/random.svg', [function(){return IntY.X()+0.3}, function(){return IntY.Y()/2 -0.6}],[1, 0.6]], {opacity:1, rotate:30});
		brd2.create('text', [function(){return IntY.X()+1.2}, function(){return IntY.Y()/2 -0.3},  function(){return '' + (IntY.Y()).toFixed(2)}], {fixed:true, fontSize:function(){return 18*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});

		brd2.create('image', ['assets/random.svg', [function(){return Ptm2.X()+0.3}, function(){return Ptm1.Y()+(Ptm2.Y()-Ptm1.Y())/2 -0.6}],[1, 0.6]], {opacity:1, rotate:30});
		brd2.create('image', ['assets/random.svg', [function(){return Ptm1.X()+(Ptm2.X()-Ptm1.X())/2}, function(){return Ptm1.Y()-0.6}],[0.6, 1.]], {opacity:1, rotate:-45});
		brd2.create('text', [function(){return Ptm2.X()+1.2}, function(){return Ptm1.Y()+(Ptm2.Y()-Ptm1.Y())/2},  function(){return 'Y_B -Y_A =' + (Ptm2.Y()-Ptm1.Y()).toFixed(2)}], {fixed:true, fontSize:function(){return 18*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
		brd2.create('text', [function(){return Ptm1.X()+(Ptm2.X()-Ptm1.X())/2}, function(){return Ptm1.Y()-1.2}, function(){return 'X_B - X_A ='+ (Ptm2.X()-Ptm1.X()).toFixed(2)}], {fixed:true, fontSize:function(){return 18*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
		 brd2.create('text', [-8, 4,  function(){return '(Y_B-Y_A)/(X_B-X_A) =' + ((Ptm2.Y()-Ptm1.Y())/(Ptm2.X()-Ptm1.X())).toFixed(2)}], {fixed:true, fontSize:function(){return 22*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
		//
		brd2.create('text', [-8, 2,  function(){return 'OD =' + (IntY.Y()).toFixed(2)}], {fixed:true, fontSize:function(){return 22*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
    },
	box2: function(){
		var brd1 = JXG.JSXGraph.initBoard('jxgbox2',{boundingbox: [-2, 4, 6, -4],keepaspectratio: true, axis:true, ticks:true, grid:true, showCopyright:false, showNavigation:false, pan:{enabled:false}, zoom:{enabled:false}});
		var line1 = brd1.create('line', [[1,0],[1, 2]], {visible:false});
		var line2 = brd1.create('line', [[2,0],[2, 2]], {visible:false});
		var PtA =brd1.create('point', [0, 0],{name:'A', face:'square',size:4, strokeColor:'black', fillColor:'yellow', fixed:true, label:{CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*brd1.canvasWidth/500.)}}});
		var PtB =brd1.create('glider', [1, 2, line1], {name:'Drag me!',face:'square',size:4, strokeColor:'black', fillColor:'yellow', label:{CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(10*brd1.canvasWidth/500.)}}});
		var PtC =brd1.create('glider', [2, -2, line2],{name:'Drag me!',face:'square', size:4, strokeColor:'black', fillColor:'yellow', label:{CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(10*brd1.canvasWidth/500.)}}});
		var PtD =brd1.create('point', [3, 0],{name:'B',face:'square',size:4, strokeColor:'black', fillColor:'yellow',fixed:true, label:{CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*brd1.canvasWidth/500.)}}});
		var start = 0.0;
		var end = 3.0;
		var f = function(x){return (3*PtB.Y()-1.5*PtC.Y()+PtD.Y()/3)*x+(-2.5*PtB.Y()+2*PtC.Y()-0.5*PtD.Y())*x*x+(0.5*PtB.Y()-0.5*PtC.Y()+(1/6)*PtD.Y())*x*x*x}
		brd1.create('functiongraph', [function(t){return f(t)}, 0, 3]);
		brd1.create('text', [-0, 3.5, '<b> Shortest Path between Point A and B <b>'],{CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*brd1.canvasWidth/500.)}});
		brd1.create('text',[1,2.5,function(){
		var i,s,f0,f1;
		var m = 45;
		var d = (end-start)/m;
		s = 0.0;
		f0 = f(start);
		for (i=1;i<=m;i++) {
			f1 = f(start+i*d);
			s += Math.sqrt(d*d+(f1-f0)*(f1-f0));
			f0 = f1;
		}
		return 'Path Length='+s.toFixed(2);}], {CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*brd1.canvasWidth/500.)}});
//brd1.create('functiongraph', [function(x){return PtD.Y()*math.cos(2*math.pi*x/2.5)}, 0, 5]);
	}
    }
export default Boxes;
